<template>
    <v-app>
        <div class="">
            <!-- Header Segment -->
            <common-home-header :callpermissionflag="axiosflag" :authDialogue="auth_error_enable" :errorDialogue="error_enable"></common-home-header>
            <v-divider></v-divider>

            <!-- Header Tabs Segment -->
            <header-tabs-component :tab_id="1" :is_user_logged_in="is_user_logged_in ? is_user_logged_in : false"></header-tabs-component>
            <v-divider></v-divider>
        </div>

        <v-divider></v-divider>

        <!-- Banner Segment -->
        <category-header :heading_name="catalog_data.category_name"></category-header>

        <!-- Body Segment -->
        <div class="py-4 grey-background">
            <div class="container">
                <!-- Breadcrumbs -->
                <div v-if="breadcrumbs" class="pb-4">
                    <v-breadcrumbs :items="breadcrumbs" class="px-0 body-text font-weight-medium">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span v-if="item != breadcrumbs[breadcrumbs.length-1]" class="body-text font-weight-medium BDBDBD-grey-color-text dark-grey-text-on-hover">{{ item.text }}</span>
                                <span v-else class="body-text font-weight-medium dark-grey-color-text">{{ item.text }}</span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </div>

                <div class="d-flex justify-center row">
                    <div class="pa-sm-4 column left-component">
                        <!-- Price Range Segment -->
                        <div class="pb-sm-3 price-segment">
                            <price-range-segment :key="priceRangeComponentKey" @getProductsOnPriceFilter="getProductsOnPriceFilter" :priceRange="priceRange"></price-range-segment>
                        </div>

                        <!-- Categories -->
                        <div v-if="subCategories.length > 0" class="pa-4 rounded-lg white-background subcategories-segment">
                            <p class="ma-0 pb-2 body-heading font-weight-semi-bold" align="left">{{ $t("Customer.priceRangeComponent.Sub_Categories")}}</p>
                            <!-- <categories-component :categories="categories"></categories-component> -->

                            <v-checkbox class="subcategory-checkbox" v-for="category, index in subCategories" :key="index" @change="updateSubCategorySelection()" v-model="selectedSubCategories"
                                :label="category.cat_name" :value="category.cat_name"
                            ></v-checkbox>
                        </div>
                    </div>

                    <div class="pa-4 column right-component">
                        <div class="pa-4 rounded products-body">
                            <!-- Products Header -->
                            <div class="pb-2 d-flex justify-space-between align-center">
                                <div class="clearFilterDiv2">
                                    <p class="ma-0 body-heading font-weight-semi-bold" align="left">{{ $t("Customer.MyOrders.Products")}}</p>
                                    <div class="clearFilterDiv">
                                    
                                    <v-btn v-if="filtersAppliedFlag" text elevation="0" @click="clearFilters()">
                                        <span class="body-heading text-transform-none-class text-decoration-none font-weight-regular light-blue-color-text link-underline">{{ $t("Customer.priceRangeComponent.Clear_Filters")}}</span>
                                    </v-btn>
                                    
                                    <div class="chipDiv">
                                        <v-chip
                                            v-if="showPriceChip"
                                            class="chipClass"
                                            close
                                            close-icon="mdi-window-close"
                                            label
                                            @click:close="removePriceFilter"
                                        >Price: {{this.minAmount}} - {{this.maxAmount}}</v-chip>
                                        <v-chip
                                            class="chipClass"
                                            close
                                            close-icon="mdi-window-close"
                                            label
                                            v-for="(item, index) in selectedSubCategories"
                                            :key="index"
                                            @click:close="removeSubCategoryChip(item)"
                                        >{{item}}</v-chip>
                                    </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="px-2 py-1 rounded d-flex align-center products-header clickable-pointer" @click="openSortPopup()">
                                        <img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/sort_by_icon_primary_light_no_bg.svg" alt="sortby-icon">

                                        <h3 class="pl-2 light-blue-color-text">{{ $t("Customer.sortBy.Sort")}}</h3>
                                    </div>
                                    
                                    <div class="sort-container">
                                        <div v-if="sortByPopup" class="pa-4 white-background rounded-lg light-grey-border sortby-popup">
                                            <div class="d-flex justify-space-between align-center">
                                                <p class="ma-0 body-heading font-weight-semi-bold">{{ $t("Customer.sortBy.Sort_By")}}</p>

                                                <img class="clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/cancel_icon_black.svg" alt="Close" @click="sortByPopup = false;">
                                            </div>

                                            <v-divider class="my-2"></v-divider>

                                            <div class="d-flex-column align-center sort-options-container">
                                                <div class="pa-2 d-flex justify-space-between align-center clickable-pointer" @click="sortByPopupClose('LowToHigh')">
                                                    <p class="ma-0 body-text font-weight-medium" :class="[sortByValue === 'LowToHigh' ? 'light-blue-color-text' : 'black-color-text']">{{ $t("Customer.sortBy.Low_to_High_Price")}}</p>

                                                    <img v-if="sortByValue == 'LowToHigh'" src="https://s3iconimages.mymedicine.com.mm/select_button_blue.svg" alt="Selected">
                                                </div>
                                                    
                                                <div class="pa-2 d-flex justify-space-between align-center clickable-pointer" @click="sortByPopupClose('HighToLow')">
                                                    <p class="ma-0 body-text font-weight-medium" :class="[sortByValue == 'HighToLow' ? 'light-blue-color-text' : 'black-color-text']">{{ $t("Customer.sortBy.High_to_Low_Price")}}</p>

                                                    <img v-if="sortByValue == 'HighToLow'" src="https://s3iconimages.mymedicine.com.mm/select_button_blue.svg" alt="Selected">
                                                </div>

                                                <div class="pa-2 d-flex justify-space-between align-center clickable-pointer" @click="sortByPopupClose('AToZ')">
                                                    <p class="ma-0 body-text font-weight-medium" :class="[sortByValue == 'AToZ' ? 'light-blue-color-text' : 'black-color-text']">{{ $t("Customer.sortBy.A-Z")}}</p>

                                                    <img v-if="sortByValue == 'AToZ'" src="https://s3iconimages.mymedicine.com.mm/select_button_blue.svg" alt="Selected">
                                                </div>

                                                <div class="pa-2 d-flex justify-space-between align-center clickable-pointer" @click="sortByPopupClose('ZToA')">
                                                    <p class="ma-0 body-text font-weight-medium" :class="[sortByValue == 'ZToA' ? 'light-blue-color-text' : 'black-color-text']">{{ $t("Customer.sortBy.Z-A")}}</p>

                                                    <img v-if="sortByValue == 'ZToA'" src="https://s3iconimages.mymedicine.com.mm/select_button_blue.svg" alt="Selected">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Products -->
                            <div v-if="!skeletonLoaderFlag">
                                <div v-if="productsList.length > 0" class="d-flex-column align-center products-container" :key="productComponentKey">
                                    <div id="catalog-container" class="products-segment">
                                        <div class="py-4 d-flex justify-center" v-for="product, index in productsList" :key="index">
                                            <product-component :product_data="product" button_text="Buy Now" class="product-container"></product-component>
                                        </div>
                                        <div v-if="selectedSubCategories.length <= 1 && this.exclusiveStartKey" v-infinite-scroll="loadMore"  :infinite-scroll-distance="1">
                                            <div style="padding-bottom: 90px;">
                                                <v-skeleton-loader elevation="0" type="image" width="180" class="px-2"></v-skeleton-loader>
                                                <v-skeleton-loader elevation="0" type="heading" class="pa-4"></v-skeleton-loader>
                                            </div>                                        
                                        </div>
                                    </div>

                                    <!-- Back to Top Icon -->
                                    <div v-if="productsList && productsList.length > 0" class="px-5 back-to-top-segment" align="right">
                                        <button class="back-to-top-button" @click="scrollToTop()">
                                            <img src="https://s3iconimages.mymedicine.com.mm/BackToTop_Blue_Icon.svg" alt="back-to-top">
                                        </button>
                                    </div>
                                </div>
                                <div v-else class="d-flex justify-center align-center no-products-found-segment">
                                    <p class="ma-0 body-heading font-weight-bold">{{ $t("Customer.productComponent.No_Products_found")}}</p>
                                </div>
                            </div>
                            <div v-else>
                                <div class="pt-6 pb-2 d-flex">
                                    <div>
                                        <v-skeleton-loader elevation="0" type="image" width="180" class="px-2"></v-skeleton-loader>
                                        <v-skeleton-loader elevation="0" type="heading" class="pa-4"></v-skeleton-loader>
                                    </div>

                                    <div>
                                        <v-skeleton-loader elevation="0" type="image" width="180" class="px-2"></v-skeleton-loader>
                                        <v-skeleton-loader elevation="0" type="heading" class="pa-4"></v-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import { axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils.js';

export default {
    name: 'CatalogPage',
    components: {
        'common-home-header': () => import('../../navigationComponents/commonHomeHeader.vue'),
        'category-header': () => import('../../navigationComponents/categoryHeaderWeb.vue'),
        'header-tabs-component': () => import('../../navigationComponents/headerTabsComponent.vue'),
        'price-range-segment': () => import('../priceRangeComponent.vue'),
        // 'categories-component': () => import('../catalogCategoriesComponent.vue'),
        'product-component': () => import('../productHomeComponent.vue')
    },
    props: {
        catalog_data: {
            type: Object,
            required: false
        },
        is_user_logged_in: {
            type: Boolean,
            required: false
        },
        axiosflag: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            priceRange: [0, 150000],
            loadingNextData: true,
            skeletonLoaderFlag: false,
            breadcrumbs: null,
            subCategories: [],
            productsList: [],
            showPriceChip: false,
            selectedSubCategories: [],
            sortByPopup: false,
            sortOrder: {
                sortField: 'alphabetical',
                sortValue: 'AToZ'
            },
            sortByValue: 'AToZ',
            minAmount: 0,
            maxAmount: 150000,
            productComponentKey: 0,
            exclusiveStartKey: null,
            priceRangeComponentKey: 0,
            filtersAppliedFlag: false,
            error_enable: false,
            auth_error_enable: false,
        };
    },
    mounted() {
        this.subCategories = this.catalog_data.subcategories;
        this.exclusiveStartKey = this.catalog_data.exclusiveStartKey;
        this.productsList = this.catalog_data.products;
        this.breadcrumbs = this.catalog_data.catalog_name == 'categories' ? [
            {
                text: 'HOME',
                disabled: false,
                href: window.location.origin + '/'
            },
            {
                text: 'CATEGORIES',
                disabled: false,
                href: window.location.origin + '/customer/allCategories'
            },
            {
                text: this.catalog_data.category_name.toUpperCase(),
                disabled: true,
                href: window.location.origin + '/customer/catalog/' + this.catalog_data.category_id
            },
        ] : [
            {
                text: 'HOME',
                disabled: false,
                href: window.location.origin + '/'
            },
            {
                text: this.catalog_data.category_name.toUpperCase(),
                disabled: true,
                href: window.location.origin + '/customer/catalog/' + this.catalog_data.category_id
            },
        ];
        
        setTimeout(() => {
            this.loadingNextData = false;
        }, 500)
    },
    methods: {
        removePriceFilter() {
            this.showPriceChip = false;
            this.priceRange = [0, 150000];
            this.getProductsOnPriceFilter(0, 150000);
        },
        removeSubCategoryChip(itemName) {
            this.selectedSubCategories = this.selectedSubCategories.filter(item => item !== itemName);
            this.updateSubCategorySelection();
        },
        updateSubCategorySelection() {
            this.filtersAppliedFlag = true;
            if (this.selectedSubCategories.length == 0) {
                this.filtersAppliedFlag = false;
                this.getCatalogProducts(this.sortOrder,this.minAmount, this.maxAmount, [this.catalog_data.category_id]);
            } else {
                // filters API.
                if (this.sortByValue) {
                    this.sortOrder = {
                        sortField: (this.sortByValue === 'AToZ' || this.sortByValue === 'ZToA') ? 'alphabetical' : 'price',
                        sortValue: this.sortByValue
                    };
                }

                let subCategories = this.subCategories.filter((subcategory) => this.selectedSubCategories.includes(subcategory.cat_name));
                this.getCatalogProducts(this.sortOrder,this.minAmount, this.maxAmount, subCategories.map((category) => category.cat_id));
            }
        },
        openSortPopup() {
            this.sortByPopup = true;
        },
        sortByPopupClose(sortByValue) {
            if (sortByValue != null) {
                if (this.sortByValue === sortByValue) {
                    this.sortOrder.sortValue = (sortByValue === 'AToZ') ? 'ZToA' : 'AToZ';
                }else{
                this.sortByValue = sortByValue;
                this.sortOrder.sortValue = sortByValue;
                }

                // Sort API call.
                this.sortOrder = {
                    sortField: (this.sortByValue === 'AToZ' || this.sortByValue === 'ZToA') ? 'alphabetical' : 'price',
                    sortValue: this.sortByValue
                };
                let categories = null;
                if (this.selectedSubCategories.length == this.subCategories.length || this.selectedSubCategories.length === 0) {
                    categories = [this.catalog_data.category_id];
                } else {
                    categories = this.subCategories.filter((subcategory) => this.selectedSubCategories.includes(subcategory.cat_name)).map((subcategory) => parseInt(subcategory.cat_id));
                }
                this.getCatalogProducts(this.sortOrder, this.minAmount, this.maxAmount, categories);
            }
            this.sortByPopup = false;
        },
        loadMore() {
            if (this.loadingNextData || !this.exclusiveStartKey) return;
            let categories = null;
            if (this.selectedSubCategories.length == this.subCategories.length || this.selectedSubCategories.length === 0) {
                categories = [this.catalog_data.category_id];
            } else {
                categories = this.subCategories.filter((subcategory) => this.selectedSubCategories.includes(subcategory.cat_name)).map((subcategory) => parseInt(subcategory.cat_id));
            }
            this.getNextProducts(this.sortOrder, this.minAmount, this.maxAmount, categories);
        },
        getNextProducts(sortOrder, minAmount, maxAmount, categories = [this.catalog_data.category_id]) {
            this.loadingNextData = true;
            let getAvailableProductsByCategoriesRequest = {
                categoriesList: categories,
                sortOrder: sortOrder,
                minAmount: minAmount,
                maxAmount: maxAmount,
            };
            if (getAvailableProductsByCategoriesRequest.categoriesList.length === 1) {
                getAvailableProductsByCategoriesRequest['exclusiveStartKey'] = this.exclusiveStartKey;
            }
            axios_auth_instance_epharmacy_customer.get('/products/categories', { params: getAvailableProductsByCategoriesRequest }).then((getAvailableProductsByCategoriesResponse) => {
                this.productsList = this.productsList.concat(getAvailableProductsByCategoriesResponse.data.data);
                this.skeletonLoaderFlag = false;
                this.loadingNextData = false;
                this.exclusiveStartKey = getAvailableProductsByCategoriesResponse.data.exclusiveStartKey;
            }).catch((getAvailableProductsByCategoriesError) => {
                console.log('Error fetching catalog page products data: ', getAvailableProductsByCategoriesError);
                this.loadingNextData = false;
                this.error_enable = true;
            });
        },
        getCatalogProducts(sortOrder, minAmount, maxAmount, categories = [this.catalog_data.category_id]) {
            this.skeletonLoaderFlag = true;
            let getAvailableProductsByCategoriesRequest = {
                categoriesList: this.catalog_data.catalog_name == 'categories' ? categories : null,
                collectionList: this.catalog_data.catalog_name == 'collections' ? categories : null,
                sortOrder: sortOrder,
                minAmount: minAmount,
                maxAmount: maxAmount,
            };
            axios_auth_instance_epharmacy_customer.get('/products/' + this.catalog_data.catalog_name + '/', { params: getAvailableProductsByCategoriesRequest }).then((getAvailableProductsByCategoriesResponse) => {
                this.productsList = getAvailableProductsByCategoriesResponse.data.data;
                this.skeletonLoaderFlag = false;
                this.exclusiveStartKey = getAvailableProductsByCategoriesResponse.data.exclusiveStartKey;
            }).catch((getAvailableProductsByCategoriesError) => {
                console.log('Error fetching catalog page products data: ', getAvailableProductsByCategoriesError);
                this.error_enable = true;
            });
        },
        getProductsOnPriceFilter(minPrice, maxPrice) {
            this.skeletonLoaderFlag = true;
            this.filtersAppliedFlag = true;
            this.minAmount = minPrice;
            this.maxAmount = maxPrice;
            this.priceRange = [minPrice, maxPrice]
            this.showPriceChip = true;
            if (minPrice === 0 && maxPrice === 150000) {
                this.showPriceChip = false;

            }
            if (this.selectedSubCategories.length === 0) {
                this.filtersAppliedFlag = false;
                this.getCatalogProducts(this.sortOrder, this.minAmount, this.maxAmount, [this.catalog_data.category_id]);
            } else {
                let categories = null;
                if (this.selectedSubCategories.length == this.subCategories.length) {
                    categories = [this.catalog_data.category_id];
                } else {
                    categories = this.subCategories.filter((subcategory) => this.selectedSubCategories.includes(subcategory.cat_name)).map((subcategory) => parseInt(subcategory.cat_id));
                }
                this.getCatalogProducts(this.sortOrder, this.minAmount, this.maxAmount, categories);
            }
        },
        scrollToTop() {
            const element = document.getElementById("catalog-container");

            element.scrollIntoView({ behavior: "smooth" });
        },
        clearFilters() {
            if (this.selectedSubCategories.length == 0 && this.minAmount == 0 && this.maxAmount == 150000) {
                // Nothing to clear.
                console.log('Nothing to clear');
            } else {
                this.selectedSubCategories = [];
                this.minAmount = 0;
                this.maxAmount = 150000;

                this.sortOrder = {
                    sortField: 'alphabetical',
                    sortValue: 'AToZ'
                };

                this.priceRangeComponentKey++;

                this.getCatalogProducts(this.sortOrder, this.minAmount, this.maxAmount, [this.catalog_data.category_id]);
            }

            this.filtersAppliedFlag = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';
.chipDiv {
    max-height: 50px;
    max-width: 500px;
    overflow-x: auto !important;
    white-space: nowrap;
}
.chipClass {
    margin: 0 10px;
}
.clearFilterDiv {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    margin: 0;

}
.clearFilterDiv2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.column {
    float: left;
}
.left-component {
    @media (max-width: 600px) {

    }

	@media (min-width: 601px) and (max-width: 900px) {
		width: 40%;
	}

	@media (min-width: 901px) {
		width: 30%;
	}
}

.right-component {
    @media (max-width: 600px) {
        
	}

	@media (min-width: 601px) and (max-width: 1280px) {
		width: 60%;
	}

	@media (min-width: 1281px) {
		width: 70%;
	}
}
.sort-segment {
    @media (max-width: 600px) {
        display: none;
	}

	@media (min-width: 601px) and (max-width: 1280px) {
		
	}

	@media (min-width: 1281px) {
		
	}
}

.price-segment {
    h3 {
        text-align: left;
    }
}
.products-container {
    height: 1000px;
    overflow-y: scroll;
}

.products-body {
    z-index: 0;
    background-color: white;
    width: 100%;
    // height: 100%;
}

.products-header {
    background-color: #F4F6FA;

    h1 {
        color: #48ACEF;
        line-height: 24px;
        font-weight: 500;
    }
}

.products-segment {
    display: grid;

    @media (max-width: 600px) {
        display: none;
	}

	@media (min-width: 601px) and (max-width: 920px) {
		grid-template-columns: repeat(2, 1fr);
	}

    @media (min-width: 921px) and (max-width: 1280px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (min-width: 1281px) {
        grid-template-columns: repeat(4, 1fr);
	}
}

.icon-class {
    width: 28px;
    height: 28px;
}

.sortby-popup {
    z-index: 9999;
    position: absolute;
    width: 300px;
    top: 0px;
    right: 0;
}

.sort-container {
    position: relative;
}

.no-products-found-segment {
    min-height: 20vh;
}

.back-to-top-segment {
    position: sticky;
    bottom: 20px;
}

.subcategories-segment{
    max-height: 400px;
    overflow-y: scroll;
}
::-webkit-scrollbar {
    height: 2px;
}
</style>

<style lang="scss">
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';



.subcategory-checkbox .v-input__slot {
    margin: unset !important;
    padding: unset !important;
}

.subcategory-checkbox .v-label {
    @media (max-width: 600px) {
        font-size: 12px;
	}

	@media (min-width: 601px) and (max-width: 1280px) {
		font-size: 14px;
	}

	@media (min-width: 1281px) {
		font-size: 14px;
	}
}

.subcategory-checkbox.v-input--selection-controls {
    margin-top: 0px;
}
</style>